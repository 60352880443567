import "./swiper";
import "./lightbox2";
import "./smoothscroll";
import "./gsap";

import MicroModal from "micromodal"; // es6 module
// var MicroModal = require("micromodal"); // commonjs module
MicroModal.init({
  awaitCloseAnimation: true,
  awaitOpenAnimation: true,
  disableScroll: true,
});

document.addEventListener("DOMContentLoaded", function () {
  /**
   * スクロールでヘッダーに背景色を入れる
   */
  const header = document.querySelector("header");
  const scrollThreshold = 80; // クラスを付与するスクロール量(px)

  window.addEventListener("scroll", function () {
    if (window.scrollY > scrollThreshold) {
      header.classList.add("scrolled");
    } else {
      header.classList.remove("scrolled");
    }
  });

  /**
   * ナビトグル用
   */
  const menuToggle = document.querySelector(".menu-toggle");
  const hamburger = document.querySelectorAll(".hamburger");
  const menuOverlay = document.querySelector(".menu-overlay");
  const navLists = document.querySelectorAll(".menu-overlay .menu a");

  function toggleMenu() {
    menuOverlay.classList.toggle("open");
    menuToggle.classList.toggle("open");

    // メニューを閉じるときに visibility を保持し、アニメーション後に変更
    if (!menuOverlay.classList.contains("open")) {
      menuOverlay.addEventListener("transitionend", function handler() {
        menuOverlay.style.visibility = "hidden";
        menuOverlay.removeEventListener("transitionend", handler);
      });
    } else {
      menuOverlay.style.visibility = "visible";
    }
  }

  hamburger.forEach(function (link) {
    link.addEventListener("click", function (event) {
      event.preventDefault();
      toggleMenu();
    });
  });

  navLists.forEach(function (item) {
    item.addEventListener("click", function (event) {
      event.preventDefault();
      toggleMenu();
    });
  });
});

import gsap from "gsap";

import ScrollTrigger from "gsap/ScrollTrigger";
import SplitText from "gsap/SplitText";

gsap.registerPlugin(ScrollTrigger, SplitText);

/* css ブレイクポイントの値を取得 */
const root = document.querySelector(":root");
const breakPc = window.getComputedStyle(root).getPropertyValue("--break-pc");
const breakTab = window.getComputedStyle(root).getPropertyValue("--break-tab");
const breakMaxTab = window.getComputedStyle(root).getPropertyValue("--break-maxtab");
const breakMaxSp = window.getComputedStyle(root).getPropertyValue("--break-maxsp");

/* タッチディバイスかどうかを判定する (if文の条件などに使用できる) */
const isTouchDevice = "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

window.onload = function () {
  /**
   * loading 解除
   */

  // 1
  gsap.set("#page", {
    opacity: 0,
  });

  gsap.set("#loading", {
    opacity: 1,
  });

  const tl_loading = gsap.timeline();

  tl_loading.to("#loading", {
    opacity: 0,
    duration: 0.2,
    onComplete: () => {
      afterLoadingAnim();
    },
  });
  tl_loading.to("#page", {
    opacity: 1,
    duration: 0.2,
    ease: "power2.out",
  });

  function afterLoadingAnim() {
    // something
  } // end afterLoadingAnim()
}; // window.onload

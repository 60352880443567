import lightbox from 'lightbox2/dist/js/lightbox.js';

lightbox.option({
  // disableScrolling: true,
  wrapAround: true,
  fadeDuration: 300,
  imageFadeDuration: 300,
  resizeDuration: 300,
  albumLabel: 'Image %1 / %2',
  disableScrolling: true // ライトボックス表示時にスクロールさせない
});

import Swiper from "swiper/bundle";

/**
 * css ブレイクポイントの値を取得
 */
const root = document.querySelector(":root");
const breakmaxtab = window.getComputedStyle(root).getPropertyValue("--break-maxtab");
const breakPc = window.getComputedStyle(root).getPropertyValue("--break-pc");
const breakTab = window.getComputedStyle(root).getPropertyValue("--break-tab");

const swipers = document.querySelectorAll(".swiper");

/* 指定したswiperIDのスライドをランダムにする関数 */
function shuffleSlide(swiperID) {
  const slidesContainer = document.getElementById(swiperID).querySelector(".swiper-wrapper");
  // スライド要素を配列に変換
  const slides = Array.from(slidesContainer.children);

  // スライド要素をランダムに並び替える関数
  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      let temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
  }

  // スライド要素をランダムに並び替える
  shuffleArray(slides);

  // シャッフルしたスライド要素を親要素に追加
  slides.forEach(function (slide) {
    slidesContainer.appendChild(slide);
  });
}

/**
 * 初期化 (トップ)
 */

// shuffleSlide("swiperTopMv");

const swiperTopMv = new Swiper("#swiperTopMv", {
  loop: true,
  effect: "fade",
  autoplay: {
    delay: 7000,
    disableOnInteraction: false,
  },
  speed: 2000,
  pagination: {
    el: ".swiper-pagination",
    type: "bullets",
    clickable: true,
  },

  // navigation: {
  //   nextEl: ".swiper-button-next",
  //   prevEl: ".swiper-button-prev",
  // },
});
